import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "invitation-template__in" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, { layout: "left" }, {
    content: _withCtx(() => [
      _createVNode(_component_Title, { left: true }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t(`status.terminated.${_ctx.title}`)), 1)
        ]),
        subTitle: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t(`status.terminated.${_ctx.message}`)), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}