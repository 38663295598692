
import { Options, Vue } from "vue-class-component";

import Layout from "@/components/Layout.vue";
import Title from "@/components/Title.vue";

@Options({
  components: {
    Layout,
    Title,
  },
})
export default class Terminated extends Vue {
  get title(): string {
    return (this.$route.params.title as string) || "alreadyCompletedTitle";
  }

  get message(): string {
    return (this.$route.params.message as string) || "message";
  }
}
